<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.campo_adicional.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.campo_adicional.titulos.novo')"
      com-select
      :sem-botao-ok="!buscarPermissao('CampoAdicional', 'Inserir')"
      :sem-filtro="!buscarPermissao('CampoAdicional', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>

      <template v-slot:select>
        <input-select
          v-model="cadastroSelecionado"
          :label="$t('modulos.campo_adicional.titulos.select')"
          :options="opcoes.cadastro"
          class="mb-2"
          style="width: 430px"
          em-filtro
        />
      </template>
    </cabecalho-pagina>
    <div class="d-flex align-center">
      <botao-padrao
        v-if="buscarPermissao('CampoAdicional', 'Ordenar')"
        color="secondary"
        outlined
        :disabled="!tabela.selecionados.length"
        @click="movimentarItem('cima')"
      >
        <v-icon> $arrowUp </v-icon>
      </botao-padrao>

      <botao-padrao
        v-if="buscarPermissao('CampoAdicional', 'Ordenar')"
        color="secondary"
        outlined
        class="mx-2"
        :disabled="!tabela.selecionados.length"
        @click="movimentarItem('baixo')"
      >
        <v-icon> $arrowDown </v-icon>
      </botao-padrao>

      <botao-padrao
        v-if="buscarPermissao('CampoAdicional', 'Ordenar')"
        color="secondary"
        outlined
        @click="salvarOrdenacao"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar_ordenacao') }}
      </botao-padrao>

      <botao-padrao
        v-if="podeExcluir && buscarPermissao('CampoAdicional', 'Deletar')"
        class="my-2 ml-2"
        outlined
        color="danger"
        @click="excluirRegistros"
      >
        <v-icon>$mdiTrashCanOutline</v-icon>
        {{ $t('geral.botoes.excluir') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      filtros-com-colunas
      filtro-global-focus
      :mostrar-acoes="true"
      :mostrar-seletor="
        buscarPermissao('CampoAdicional', 'Deletar') ||
          buscarPermissao('CampoAdicional', 'Ordenar')
      "
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          v-if="buscarPermissao('CampoAdicional', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item @click="abrirEditar(slotProps.data)">
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import CampoAdicionalService from '@common/services/cadastros/CampoAdicionalService.js';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import Filtro from './filtro.vue';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      cadastroSelecionado: 0,
      opcoes: {
        cadastro: [],
      },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'sequencia',
            text: this.$t('modulos.campo_adicional.tabela.sequencia'),
            sortable: false,
          },
          {
            value: 'nome',
            text: this.$t('modulos.campo_adicional.tabela.nome'),
            sortable: true,
            width: 300,
          },
          {
            value: 'tipoCampo',
            text: this.$t('modulos.campo_adicional.tabela.tipoCampo'),
            sortable: true,
            width: 200,
            formatter: (v) => {
              if (v)
                return this.enum.enumTipoCampo.find((el) => el.value === v)
                  ?.text;
            },
          },
          {
            value: 'obrigatorio',
            text: this.$t('modulos.campo_adicional.tabela.obrigatorio'),
            sortable: true,
            formatter: (v) => {
              return helpers.BoleanoEnum.find((el) => el.value === v)?.text;
            },
            width: 200,
          },
          {
            value: 'quantidadeTipoInstrumentos',
            text: this.$t(
              'modulos.campo_adicional.tabela.quantidadeTipoInstrumentos'
            ),
            sortable: true,
            width: 180,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      enum: {
        enumTipoCampo: [],
      },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  watch: {
    cadastroSelecionado: function () {
      this.listarRegistros();
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'CampoAdicional', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
    this.cadastroSelecionado = filtros?.inputSelect;
  },
  async mounted() {
    await this.buscarTipoCampoEnum();
    await this.buscarEntidadeEnum();
    if (!this.cadastroSelecionado)
      this.cadastroSelecionado = this.opcoes.cadastro[0].value;
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.campo_adicional.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina,
      entidade = this.cadastroSelecionado,
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        paginaAtual,
        porPagina,
        inputSelect: entidade,
        filtroAplicado: this.filtroAplicado,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        Entidade: entidade ?? '',
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      CampoAdicionalService.listar(parametros)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas= res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: async function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        const listaIdExclusao = this.tabela.selecionados.map((s) => s.id);
        CampoAdicionalService.excluir(listaIdExclusao)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(
              this.$t(`modulos.campo_adicional.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    movimentarItem: function (direcao) {
      const incremento = direcao === 'cima' ? -1 : 1;

      let itensSelecionados = [...this.tabela.selecionados];
      let itensSelecionadosComIndex = itensSelecionados.map((item) => {
        return {
          ...item,
          index: this.tabela.dados.indexOf(item),
        };
      });

      itensSelecionadosComIndex.sort((a, b) =>
        incremento === -1 ? a.index - b.index : b.index - a.index
      );

      itensSelecionadosComIndex.forEach((item) => {
        const novaLista = [...this.tabela.dados];
        const novoIndex = item.index + incremento;

        if (novoIndex < 0 || novoIndex >= novaLista.length) {
          return;
        }
        const itemListaAntes = novaLista[item.index + incremento];

        novaLista[item.index + incremento] = novaLista[item.index];
        novaLista[item.index] = itemListaAntes;
        this.tabela.dados = [...novaLista];
      });
    },
    salvarOrdenacao: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      CampoAdicionalService.salvarOrdenacao(this.tabela.dados)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.campo_adicional.salvar_ordenacao_sucesso`)
          );
          this.tabela.selecionados = [];
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    abrirNovo: function () {
      this.$router.push({ name: 'campo-adicional-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'campo-adicional-editar',
        params: { id: item.id },
      });
    },
    buscarEntidadeEnum: async function () {
      await EnumeradorService.buscar('EnumCampoAdicionalEntidade').then(
        (res) => {
          this.opcoes.cadastro = res;
        }
      );
    },
    buscarTipoCampoEnum: async function () {
      await EnumeradorService.buscar('EnumCampoAdicionalTipoCampo').then(
        (res) => {
          this.enum.enumTipoCampo = res;
        }
      );
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
  },
};
</script>
<style lang="scss">
.tabela-campos-adicionais {
  th:nth-child(3),
  td:nth-child(3) {
    width: 100px !important;
  }
}
</style>
